import React from 'react';

/**
 * A wrapper function for React.memo with additional functionality.
 * @param {React.ComponentType} Component - The component to be memoized.
 * @param {Function} [areEqual] - Optional custom comparison function.
 * @returns {React.MemoExoticComponent} - The memoized component.
 */
const withMemo = (Component, areEqual) => {
	const MemoizedComponent = React.memo(Component, areEqual);

	// Additional functionality or logging can be added here if needed.

	return MemoizedComponent;
};

export default withMemo;
