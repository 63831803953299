import useBrandConfigStore from '../../../../../context/store/useBrandConfigStore.store';

const { create } = require('zustand');

const usePopupNoticeBoardStore = create((set) => ({
	openPopupNoticeBoard: false,
	setOpenPopupNoticeBoard: () => {
		const { config } = useBrandConfigStore.getState();
		const bannerPopupBrand = config?.bannerPopUp?.bannerPopUp;

		const newOpen = bannerPopupBrand?.isActive ? bannerPopupBrand?.isActive === 'active' : false;

		set({ openPopupNoticeBoard: newOpen });
	},
	setClosePopupNoticeBoard: () => {
		set({ openPopupNoticeBoard: false });
	}
}));

export default usePopupNoticeBoardStore;
