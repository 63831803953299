import { useEffect, useMemo, useState } from 'react';

const useWindowHook = () => {
	const [screenWidth, setScreenWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : 0
	);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		const debouncedHandleResize = debounce(handleResize, 100); // Debounce resizing

		window.addEventListener('resize', debouncedHandleResize);

		// Initial setting of screenWidth
		handleResize();

		return () => window.removeEventListener('resize', debouncedHandleResize);
	}, []);

	const isMobile = useMemo(() => screenWidth < 1024, [screenWidth]);

	const closeWindowTabWithTimeout = () => {
		setTimeout(() => {
			window.opener = null;
			window.open('', '_self');
			window.close();
		}, 8000);
	};

	return { screenWidth, isMobile, closeWindowTabWithTimeout };
};

function debounce(func, wait) {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

export default useWindowHook;
