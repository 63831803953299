import dynamic from 'next/dynamic';

import withMemo from '../../../hocs/withMemo';
import useWindowHook from '../../../../hooks/useWindowHook.hook';
import useRouterHook from '../../../../hooks/useRouterHook.hook';
// import useBrandConfigStore from '../../../../context/store/useBrandConfigStore.store';
import usePopupNoticeBoardStore from '../../organism/PopupNoticeBoard/hooks/usePopupNoticeBoardStore.store';
// import Sidebar from '../../organism/Sidebar';
// import PopupNoticeBoard from '../../organism/PopupNoticeBoard';

// import Sidebar from '../../organism/Sidebar';
// import NavbarMobile from '../../organism/NavbarMobile';
// import Header from '../../organism/Header/Header';
// import Container from '../../organism/Container';
// import Footer from '../../organism/Footer';
// import PopupNoticeBoard from '../../organism/PopupNoticeBoard';
// import BannerMainOut from '../../molecules/Banner/BannerMainOut';
// import BannerGameCategory from '../../pages/GamesPage/components/BannerGameCategory';
// import GameSearchPageComponent2 from '../../molecules/GameSearchComponent/components/GameSearchPageComponent2';
// import LayoutModal from '../../../features/modal/LayoutModal';
// import HeadLayout from '../HeadLayout';
// import ScriptLayout from '../ScriptLayout';
// import BannerGameCategory from '../../pages/GamesPage/components/BannerGameCategory';

const Chat = dynamic(() => import('@/components/organism/Chat'), {
	ssr: false
});
const Sidebar = dynamic(() => import('../../organism/Sidebar'), {
	ssr: false
});
const NavbarMobile = dynamic(() => import('@/components/organism/NavbarMobile'), { ssr: false });
const Header = dynamic(() => import('@/components/organism/Header/Header'), { ssr: false });
const Container = dynamic(() => import('@/components/organism/Container'), { ssr: false });
const Footer = dynamic(() => import('@/components/organism/Footer'), { ssr: false });
const PopupNoticeBoard = dynamic(() => import('../../organism/PopupNoticeBoard'));
const BannerMainOut = dynamic(() => import('../../molecules/Banner/BannerMainOut'), {
	ssr: false
});
const BannerGameCategory = dynamic(
	() => import('../../pages/GamesPage/components/BannerGameCategory'),
	{
		ssr: false
	}
);

const GameSearchPageComponent2 = dynamic(() =>
	import('@/components/molecules/GameSearchComponent/components/GameSearchPageComponent2')
);
const LayoutModal = dynamic(() => import('../../../features/modal/LayoutModal'), { ssr: false });
const HeadLayout = dynamic(() => import('../HeadLayout'), { ssr: false });
const ScriptLayout = dynamic(() => import('../ScriptLayout'), { ssr: false });

function Layout({ children }) {
	const { openPopupNoticeBoard } = usePopupNoticeBoardStore((state) => ({
		openPopupNoticeBoard: state.openPopupNoticeBoard
	}));
	const { router } = useRouterHook();
	const { isMobile } = useWindowHook();

	return (
		<>
			<div className="overflow-auto">
				{/* head */}
				<HeadLayout />
				{/* all script */}
				<ScriptLayout />

				<Chat />
				<LayoutModal />
				<Header />
				{isMobile ? <NavbarMobile /> : null}
				<div className="flex flex-col bg-container-bg-primary text-text-color-primary">
					{openPopupNoticeBoard && router.asPath === '/' && <PopupNoticeBoard />}
					{router.asPath.startsWith('/games') && <BannerGameCategory />}
					{router.asPath === '/' && <BannerMainOut />}
					<div className="flex justify-center relative flex-grow">
						<Container>{children}</Container>
					</div>
				</div>
				<Sidebar />
				<GameSearchPageComponent2 />
				<Footer />
			</div>
		</>
	);
}

export default withMemo(Layout);
